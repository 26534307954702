@font-face {
    font-family: 'VCR';
    src: url('../fonts/VCR_OSD_MONO_1.001.ttf');
    /* for cross browser blah blah */
    /* src: url("CustomFont.woff") format("woff"),
    url("CustomFont.otf") format("opentype"),
    url("CustomFont.svg#filename") format("svg"); */
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;

    font-family: 'VCR', Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
}

/* this allows everything to be centered horizontally */
.full-width-column {
    height: 100%;
    width: 100%;
    background-color: rgb(250, 250, 76);
    position: absolute;
    top: 0;
    left: 0;
}

/* https://stackoverflow.com/questions/2941189/how-to-overlay-one-div-over-another-div */
.main-content-container {
    position: relative;
    /* border: 15px inset grey; */
    border: 15px inset #9588cb;
    box-sizing: border-box; /* Include padding and border in element's width and height */
    overflow: hidden;
    margin-top: 2%;
    width: 90%;
    height: 70%;
    margin-left: auto;
    margin-right: auto;
}

#scene-container {
    /* positioning */
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

#overlay {
    /* positioning */
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

#overlay > div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

#overlay > div > button {
    display: block;
    color: transparent;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    padding: 0%;
}

#overlay > div > button > img {
    display: block;
    width: 400px;
    /* -webkit-animation: spin 12s linear infinite;
    -moz-animation: spin 12s linear infinite;
    animation: spin 12s linear infinite; */
}

#overlay > div > button > img:hover {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

.webcam-status-overlay {
    /* positioning */
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.webcam-status-overlay > .vertical-positioning-container {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.webcam-status-overlay > .vertical-positioning-container > .centered-container {
    position: relative;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.webcam-status-overlay > .vertical-positioning-container > .centered-container > img {
    width: 40px;
    /* https://stackoverflow.com/questions/12690444/css-border-on-png-image-with-transparent-parts */
    -webkit-filter: drop-shadow(5px -1px 4px #343434);
    filter: drop-shadow(5px -1px 4px #343434);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.bottom_bar_container {
    position: relative;
    width: 90%;
    height: 20%;
    margin-left: auto;
    margin-right: auto;
}
/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.bottom_bar {
    /* position: absolute; */
    z-index: 2;
    bottom: 0;
    /* left: auto; */
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    /* background-color: rgb(250, 250, 76); */
    color: black;
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap; */
    /* Then we define how is distributed the remaining space */
    justify-content: space-around;
    padding: 0;
}

.bottom_bar > img {
    position: absolute;
    z-index: 3;
}

.bottom_bar > div {
    /* border: 10px inset grey; */
    border: 10px inset #9588cb;
    background-color: rgb(72, 72, 72);
    color: white;
    font-size: 10px;
    border-collapse: separate;
    padding: 0%;
    height: 100%;
    width: 21.25%;
    margin: 0px;
    font-size: 0.85em;
    box-sizing: border-box; /* Include padding and border in element's width and height */
    text-align: center;
    overflow: hidden;
}

#project-details {
    overflow: scroll;
}

.box-title {
    font-weight: bold;
    margin: 1%;
}

.box-content {
    font-size: 12px;
    text-align: left;
    margin: 5%;
}
.box-content > a:link {
    outline: none;
    text-decoration: none;
    color: yellow;
}
.box-content > a:visited {
    color: yellow;
}
.box-content > a:hover {
    background-color: rgba(50, 50, 50, 1);
    color: yellow;
}

#self-view-box {
    width: 15%;
    display: flex;
    overflow: hidden;
    background-color: black;
    position: relative;
}

#mini-map-box {
    display: flex;
    overflow: hidden;
    background-color: black;
    position: relative;
}

#self-view-canvas-container {
    width: 260px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -130px;
    margin-top: -100px;
}

#mini-map-canvas-container {
    width: 300px;
    height: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -150px;
}

#credits-box {
    text-align: center;
    -webkit-animation: scroll 20s linear infinite;
    -moz-animation: scroll 20s linear infinite;
    animation: scroll 20s linear infinite;
}

#html-project-list {
    text-align: left;
    overflow: scrollable;
    white-space: nowrap;
    overflow-y: auto;
    overflow-x: auto;

    /* -webkit-animation: scroll 60s linear infinite;
  -moz-animation: scroll 60s linear infinite;
  animation: scroll 60s linear infinite; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
#project-details::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#project-details {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@keyframes scroll {
    from {
        margin-top: 80%;
    }
    to {
        margin-top: -100%;
    }
}
/* PROJECT MODAL */
/* https://www.w3schools.com/howto/howto_css_modals.asp */
/* The Modal (background) */
.project-modal {
    /* display: none; */
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content/Box */
.project-modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    overflow-y: scroll;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    /* Could be more or less, depending on screen size */
}

.project-modal-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 30%; */
    max-height: 220px;
}

.project-modal-title {
    text-align: center;
}

.project-modal-names {
    text-align: center;
    font-size: 14px;
}

.project-modal-text {
    font-size: 14px;
    line-height: 17px;
    font-family: monospace;
    width: 80%;
    margin-left: 5%;
}
.project-modal-links-header {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.project-modal-link-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Audio Button */
.audio-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-audio {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    /* background-image: url(../images/musicswitch.png); */
    background-size: 40px;
}

.btn-audio.is-active {
    background-position: 0 0;
}

.btn-audio.is-inactive {
    background-position: 0 -40px;
}

/* Tutorial Stuff */
.tutorial_box {
    position: absolute;
    z-index: 3;
    bottom: 3%;
    /* left: auto; */
    margin-left: 58%;
    margin-right: 2%;
    /* background-color: rgb(250, 250, 76); */
    display: flex;
    flex-direction: row-reverse;
    /* flex-wrap: nowrap; */
    /* Then we define how is distributed the remaining space */
    justify-content: space-around;
    border: 10px inset #9588cb;
    background-color: #fafa4c;
    color: black;
    /* font-size: 20px; */
    border-collapse: separate;
    padding: 0.75%;
    height: 20%;
    width: 40%;
    /* margin: 0%; */
    font-size: 1em;
    box-sizing: border-box; /* Include padding and border in element's width and height */
    text-align: center;
    overflow: hidden;
    visibility: hidden;
    /* float: right; */
}

/* welcome screen */
body {
    font-family: 'VCR', Arial, sans-serif;
}

/* The Modal (background) */
#welcome-screen-modal {
    display: none; /* Hidden by default */
    position: relative; /*Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full z */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

#welcome-screen-container {
    display: flex;
    max-height: 80%;
    justify-content: center;
    align-items: center;
    background-color: #f8f7e1;
    padding: 10px;
    color: black;
    margin: 15% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
    border: 15px inset #9588cb;
    width: 90%; /* Could be more or less, depending on screen size */
}

#welcome-screen-container > div {
    width: 100%;
}

#image-container {
    order: 1;
    max-height: 500px;
}
#image-container > img {
    text-align: center;
    max-height: 350px;
}
#text-container {
    flex-grow: 4;
    order: 2;
}

/* Full-width input fields */

/* Set a style for all buttons */
.button2 {
    background-color: #5badff;
    color: white;
    padding: 14px 20px;
    margin: auto;
    border: none;
    cursor: pointer;
    width: auto;
    display: inline-block;
}

.button2:hover {
    opacity: 0.8;
}

#enterButton {
    background: none;
    border: 0px;
    display: inline-block;
    vertical-align: middle;
}

#enterButton > img {
    max-width: 150px;
    max-height: 80px;

    vertical-align: middle;
}

#enterButton:hover > img {
    max-width: 110px;
    max-height: 60px;

    vertical-align: middle;
}

#controls-explanation {
    display: inline-block;
    width: 200px;
    height: 100px;
}

#controls-explanation > img {
    max-height: 100%;
    max-width: 100%;
}

#enter-buttons {
    display: inline-block;
}

/* Add Zoom Animation */
.animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
    }
}

@keyframes animatezoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
    span.psw {
        display: block;
        float: none;
    }
    .cancelbtn {
        width: 100%;
    }
}
